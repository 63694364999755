<template>
  <v-card
    elevation="4"
    class="data-table"
    :class="canBeSelected ? 'data-table--selectable' : ''"
  >
    <v-data-table
      calculate-widths
      :headers="headers"
      :items="itemsLoading ? [] : items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="searchTerm"
      :sort-by="sortBy.value"
      :sort-desc="sortDesc"
      hide-default-footer
      hide-default-header
      class="elevation-0 custom-data-table"
      :server-items-length="itemsTotal"
    >
      <template v-slot:body v-if="itemsLoading">
        <template v-if="$vuetify.breakpoint.lgAndDown">
          <config-table-item-skeleton-mobile v-for="item in 5" :key="item" />
        </template>
        <template v-else>
          <config-table-skeleton-sort />
          <config-table-skeleton-item />
        </template>
      </template>

      <template v-slot:no-data>
        <template v-if="!itemsLoading && !searchTerm">
          <TableFullScreenMessage :title="$t('heading.instance.advanced.users.noData')">
            <template v-slot:image>
              <svg
                width="73"
                height="65"
                viewBox="0 0 73 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.00306 42.5152C-6.69594 33.3411 8.38886 4.28969 29.7723 0.615481C53.7802 -3.51515 70.6222 14.4679 66.4688 35.2124C61.8817 58.102 31.6893 69.5355 23.0629 61.4111C17.9966 56.6415 26.4404 50.7764 20.2102 44.9114C13.9801 39.0464 7.54448 46.7599 3.00306 42.4924V42.5152Z"
                  fill="#F9F9FB"
                />
                <path
                  d="M55.1235 5.01999L51.1235 1.01999"
                  stroke="#07C07E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M51.1235 5.01999L55.1235 1.01999"
                  stroke="#07C07E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.12354 39.02L1.12354 35.02"
                  stroke="#07C07E"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M1.12354 39.02L5.12354 35.02"
                  stroke="#07C07E"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M68.1235 17.02C69.7804 17.02 71.1235 15.6768 71.1235 14.02C71.1235 12.3631 69.7804 11.02 68.1235 11.02C66.4667 11.02 65.1235 12.3631 65.1235 14.02C65.1235 15.6768 66.4667 17.02 68.1235 17.02Z"
                  stroke="#07C07E"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M14.7554 13.0453H56.5608C57.9159 13.0453 59 14.2844 59 15.7763V24.02H12V16.105C12 14.4108 13.2422 13.02 14.7554 13.02V13.0453Z"
                  fill="#EEEEF4"
                />
                <path
                  d="M56.2446 53.02L14.4392 53.02C13.0841 53.02 12 51.9572 12 50.6774V24.02L59 24.02V50.3738C59 51.827 57.7578 53.02 56.2446 53.02Z"
                  fill="white"
                />
                <path
                  d="M57.5781 13.02H14.4219C13.0843 13.02 12 14.0976 12 15.4269V50.6131C12 51.9424 13.0843 53.02 14.4219 53.02H57.5781C58.9157 53.02 60 51.9424 60 50.6131V15.4269C60 14.0976 58.9157 13.02 57.5781 13.02Z"
                  stroke="#B0B0D3"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13 25.02H59"
                  stroke="#B0B0D3"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M20 21.02C21.1046 21.02 22 20.1246 22 19.02C22 17.9154 21.1046 17.02 20 17.02C18.8954 17.02 18 17.9154 18 19.02C18 20.1246 18.8954 21.02 20 21.02Z"
                  stroke="#797997"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <rect
                  x="18"
                  y="29.02"
                  width="8"
                  height="8"
                  rx="2"
                  stroke="#B0B0D3"
                  stroke-width="2"
                />
                <rect
                  x="18"
                  y="41.02"
                  width="8"
                  height="8"
                  rx="2"
                  stroke="#B0B0D3"
                  stroke-width="2"
                />
                <rect
                  x="31"
                  y="29.02"
                  width="8"
                  height="8"
                  rx="2"
                  stroke="#B0B0D3"
                  stroke-width="2"
                />
                <rect
                  x="31"
                  y="41.02"
                  width="8"
                  height="8"
                  rx="2"
                  stroke="#B0B0D3"
                  stroke-width="2"
                />
                <rect
                  x="45"
                  y="29.02"
                  width="8"
                  height="8"
                  rx="2"
                  stroke="#B0B0D3"
                  stroke-width="2"
                />
                <rect
                  x="45"
                  y="41.02"
                  width="8"
                  height="8"
                  rx="2"
                  stroke="#B0B0D3"
                  stroke-width="2"
                />
                <path
                  d="M46 47.02C49.866 47.02 53 43.886 53 40.02C53 36.154 49.866 33.02 46 33.02C42.134 33.02 39 36.154 39 40.02C39 43.886 42.134 47.02 46 47.02Z"
                  fill="#F9F9FB"
                  fill-opacity="0.6"
                  stroke="#797997"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M51 45.02L56 50.02"
                  stroke="#797997"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </template>
          </TableFullScreenMessage>
        </template>
        <data-iterator-no-results-container
          v-if="searchTerm"
          @clearAllFilters="$emit('update:searchTerm', '')"
          :searchTerm="searchTerm"
        />
      </template>

      <template
        v-if="$vuetify.breakpoint.width >= 1400 && !itemsLoading"
        v-slot:header="{ isMobile, props, on }"
      >
        <table-custom-sort
          :isMobile="isMobile"
          :props="props"
          :v-on="on"
          v-on="$listeners"
        />
      </template>

      <template v-slot:item.user_registered="{ item }">
        {{ item.user_registered_text }}
      </template>

      <template v-slot:item.role="{ item }">
        <UsersRoleLabel :role="item.role" />
      </template>

      <template v-slot:item.email="{ item }">
        <a
          :href="`mailto:${item.email}`"
          class="d-flex align-center primary-on-hover base-text"
        >
          <v-avatar
            :min-width="28"
            :width="28"
            :height="28"
            large
            color="white"
            class="mr-2"
          >
            <v-img
              :width="28"
              :height="28"
              :src="getAvatarUrl(item.email, 28)"
            />
          </v-avatar>
          <span class="primary-on-hover base--text">
            {{ item.email }}
            <v-icon size="16" class="show-on-hover" color="gray lighten-1">
              $arrowCornerRightIcon
            </v-icon>
          </span>
        </a>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex actions-row">
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                @click="$emit('action-button-edit', item)"
              >
                <v-icon>$edit</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.user.edit') }}</span>
          </v-tooltip>
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                @click="$emit('action-button-reset-password', item)"
              >
                <v-icon>$restore</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.resetPassword') }}</span>
          </v-tooltip>
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                :disabled="item.id === 1"
                @click="$emit('action-button-delete', item)"
              >
                <v-icon>$thrash</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.user.delete') }}</span>
          </v-tooltip>
        </div>
      </template>

      <template
        v-if="$vuetify.breakpoint.width < 1400"
        v-slot:item="{ item, headers }"
      >
        <tr>
          <td class="w-100 px-0">
            <div class="mobile-table-item">
              <div class="mobile-table-item__values">
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[0])"
                  >
                    {{ $t('table.header.id') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.id }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[1])"
                  >
                    {{ $t('table.header.login') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.username }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[2])"
                  >
                    {{ $t('table.header.displayName') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.display_name }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[3])"
                  >
                    {{ $t('table.header.email') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.email }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[4])"
                  >
                    {{ $t('table.header.role') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.role }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[5])"
                  >
                    {{ $t('table.header.registered') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.user_registered_text }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__actions">
                  <v-tooltip
                    transition="custom-tooltip"
                    open-delay="150"
                    bottom
                    z-index="99"
                    offset-overflow
                    nudge-bottom="4px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        small
                        @click="$emit('action-button-edit', item)"
                      >
                        <v-icon>$edit2</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('button.user.edit') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    transition="custom-tooltip"
                    open-delay="150"
                    bottom
                    z-index="99"
                    offset-overflow
                    nudge-bottom="4px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        small
                        @click="$emit('action-button-reset-password', item)"
                      >
                        <v-icon>$restore</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('button.resetPassword') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    transition="custom-tooltip"
                    open-delay="150"
                    bottom
                    z-index="99"
                    offset-overflow
                    nudge-bottom="4px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        small
                        @click="$emit('action-button-delete', item)"
                      >
                        <v-icon>$thrash</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('button.user.delete') }}</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template v-slot:footer>
        <div>
          <data-iterator-footer
            :page="page"
            :numberOfPages="numberOfPages"
            :possibleItemsPerPage="possibleItemsPerPage"
            :listStyle="listStyle"
            :itemsPerPage="itemsPerPage"
            :itemsTotal="itemsTotal"
            v-on="$listeners"
            :perPageLabel="$t('table.footer.user')"
          >
          </data-iterator-footer>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { getAvatarUrl } from "@/apis/HelperFunctions";

import DataIteratorMixin from "../../../mixins/DataIteratorMixin";
import CustomTableMixin from "../../../mixins/CustomTableMixin";

import DataIteratorFooter from "../DataIteratorFooter.vue";
import TableCustomSort from "../TableCustomSort.vue";

import ConfigTableSkeletonItem from "./ConfigTableSkeletonItem.vue";
import ConfigTableSkeletonSort from "./ConfigTableSkeletonSort.vue";
import ConfigTableItemSkeletonMobile from "./ConfigTableItemSkeletonMobile.vue";
import UsersRoleLabel from "../../labels/UserRoleLabel.vue";
import TableFullScreenMessage from "../../dataIterator/tables/TableFullScreenMessage.vue";
import DataIteratorNoResultsContainer from "../DataIteratorNoResultsContainer.vue";

export default {
  components: {
    DataIteratorFooter,
    TableCustomSort,
    ConfigTableSkeletonItem,
    ConfigTableSkeletonSort,
    ConfigTableItemSkeletonMobile,
    UsersRoleLabel,
    TableFullScreenMessage,
    DataIteratorNoResultsContainer,
  },
  data: function () {
    return {
      canBeSelected: false,
    };
  },
  mixins: [DataIteratorMixin, CustomTableMixin],
  props: {
    searchTerm: String,
    headers: Array,
    itemsTotal: Number,
    itemsLoading: Boolean,
  },
  methods: {
    hideSecret: function (secret) {
      return "•".repeat(secret.length + 1);
    },
    getAvatarUrl: function (email) {
      return getAvatarUrl(email);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  margin-left: 10px;
  @media (max-width: 1183px) {
    margin-left: 0px;
    margin-right: 10px;
  }
}
.v-data-table::v-deep {
  td {
    color: var(--v-gray-darken2);
  }
}
.v-avatar {
  border-color: var(--v-gray-lighten4) !important;
  border-style: solid !important;
  border-width: 1px !important;
}
</style>
